import gsap from 'gsap'

import Scroll from '../utils/scroll'

const pageurl = document.querySelector('body').id

if(pageurl == 'conceito'){
    const scroll = new Scroll()

    // gsap.ticker.add(() => { 
    //     gsap.to('h2', {
    //       y: -scroll.screenPosition/2,
    //       autoAlpha: scroll.screenPosition/500,
    //       ease: 'power2.out',
    //       duration: 1.6
    //     })
    // })
}