import gsap from 'gsap'
import $ from 'jquery'

const pageurl = document.querySelector('body').id

if(pageurl == 'home'){
    const intro = document.querySelector('.intro')
    const windowSize = intro.offsetWidth

    intro.addEventListener('mousemove', (e) => {
        const { clientX, clientY } = e;
        const x = clientX;
        const y = clientY;
    
        gsap.to(".shape-circle", {
            left: `calc(${x}px - 140px)`,
            top: `calc(${y}px - 140px)`,
            autoAlpha: 1,
            duration: 0.8,
            ease: "sine.out",
        })
    })
}