import gsap from 'gsap'
import Swiper from 'swiper';
import Inputmask from 'inputmask'
import $ from 'jquery'

import Scroll from '../utils/scroll'

const pageurl = document.querySelector('body').id

if(pageurl == 'arquitetura'){
  const scroll = new Scroll()

  let nameSend = '';
  let emailSend = '';

  const conheca = $('#conheca').offset().top - 100
  const orcamento = $('#orcamento').offset().top

  gsap.ticker.add(() => { 
    gsap.to('.title-banner', {
      y: scroll.screenPosition/10,
      ease: 'power2.out',
      duration: 1.6
    })

    gsap.to('.pelicula-description svg', {
      y: -scroll.screenPosition/7,
      autoAlpha: scroll.screenPosition/500,
      ease: 'power2.out',
      duration: 1.6
    })

    gsap.to('.shape-circle', {
      y: scroll.screenPosition/5,
      ease: 'power2.out',
      duration: 1.6
    })
  })

  $('.button-conheca').on('click', function(){
    $("body, html").animate({
      scrollTop : conheca
    }, 1200);

    return false
  })

  $('.button-orcamento').on('click', function(){
    $("body, html").animate({
      scrollTop : orcamento
    }, 1200);

    return false
  })
  

  const swiperProdutos = new Swiper('.swiper-produtos', {
      slidesPerView: 3,
  
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
  
      breakpoints: {
        320: {
          slidesPerView: 'auto',
          spaceBetween: 20,
        },
  
        992: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
  
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      },
  });

  const nomeForm = document.querySelector('#username')
  const emailForm = document.querySelector('#email')
  const buttonForm = document.querySelector('#buttonForm')

  buttonForm.classList.add('disabled')

  nomeForm.addEventListener('keyup', () => {
    handleSubmit()
  })

  emailForm.addEventListener('keyup', () => {
    handleSubmit()
  })

  function handleSubmit() {
    if(nomeForm.value != '' && emailForm.value != '') {
      buttonForm.classList.remove('disabled')

      nameSend = nomeForm.value
      emailSend = emailForm.value

      buttonForm.setAttribute('href', `https://api.whatsapp.com/send?phone=554730276909&text=Olá, me chamo ${nameSend}, meu email é ${emailSend} e estava analisando películas de arquitetura`)
    }
  }

  const uvDescription = document.querySelectorAll('.uv-description')

  uvDescription.forEach(description => {
    description.addEventListener('mouseenter', (e) => {
      const data = e.target.getAttribute('data-description')

      gsap.to('.'+data, {
        duration: .8,
        "clip-path": "inset(0% 0% 0% 0%)",
        scale: 1.05,
        ease: "power2.inOut"
      })
    })

    description.addEventListener('mouseleave', (e) => {
      const data = e.target.getAttribute('data-description')

      gsap.to('.'+data, {
        duration: .6,
        scale: 1,
        "clip-path": "inset(100% 0% 0% 0%)",
        ease: "power2.inOut"
      })
    })
  })

  Inputmask({"mask": "(99) 99999-9999"}).mask('#phone')

  var arquiteturaThumbs = new Swiper('.arquitetura-thumbs', {
    spaceBetween: 10,
    slidesPerView: 3,
    watchSlidesProgress: true,

    breakpoints: {
      320: {
        slidesPerView: 1,
      },

      992: {
        slidesPerView: 3,
        spaceBetween: 40,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      }
    },
  });

  var arquiteturaTop = new Swiper('.arquitetura-top', {
    spaceBetween: 10,
    allowTouchMove: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: arquiteturaThumbs
    }
  });

  const video = document.querySelector('.background-video')

  document.querySelector('.video').addEventListener('click', () => {
    gsap.to('.banner-video', {
      height: '80vh',
      duration: .6,
      ease: 'power2.inOut'
    })

    video.play()
    video.muted = !video.muted
  })

  document.querySelector('.close-video').addEventListener('click', () => {
    gsap.to('.banner-video', {
      height: '0',
      duration: .6,
      ease: 'power2.inOut'
    })

    video.pause()
  })
}