import Preload from 'preload-it'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class Load {
    constructor(options){
        this.container = options.domElement

        this.preload = Preload()
        this.preloadProgress = document.querySelector('.preload span')

        this.randomY = gsap.utils.random(30, 60)
        this.randomDuration = gsap.utils.random(0.5, 1.5)
        this.randomStagger = gsap.utils.random(0.1, 0.4)

        this.pageurl = document.querySelector('body').id

        this.load()
    }

    load() {
        this.preload.fetch([
            '../public/images/favicon.png',
            '../public/images/share.jpg'
        ]).then(() => {
            // Animation executed when loading finish
            const enterAnimation = gsap.timeline({ delay: 1 })
        
            enterAnimation.to('.preload', {
                duration: .5,
                autoAlpha: 0,
                ease: 'power3.inOut'
            })
            enterAnimation.to('.preload span', {
                duration: .8,
                autoAlpha: 0,
                skewY: 6,
                y: -20,
                delay: -.8,
                ease: 'power3.inOut'
            })
            enterAnimation.to('.intro-box', {
              duration: .6,
              width: '100%',
              delay: -.4,
              ease: 'power2.inOut'
            })
            enterAnimation.to('.intro-image', {
              duration: .8,
              autoAlpha: 1,
              delay: -.6,
              x: '-8rem',
              ease: 'power2.inOut'
            })
            enterAnimation.to('.intro-logo, .intro-left, .intro-right', {
              y: '-2rem',
              autoAlpha: 1,
              duration: .4,
              delay: -.4,
              stagger: .1,
              ease: 'power2.inOut'
            })
            
            if(this.pageurl != 'home'){
              // General animation
              if(document.querySelector('.transition-image')){
                document.querySelectorAll('.transition-image').forEach((image) => {
                    gsap.set(image, {
                        "clip-path": "inset(80% 0% 0% 0%)",
                    })
        
                    gsap.to(image, {
                        scrollTrigger: {
                            trigger: image,
                            end: '+=200%'
                        },
                        duration: 1.2,
                        "clip-path": "inset(0% 0% 0% 0%)",
                        ease: "sine.out"
                    });
                })
              }

              if(document.querySelector('.banner-options')){
                gsap.to('.banner-options', {
                  duration: .6,
                  bottom: 0,
                  ease: "sine.out",
                  delay: 1
                });
              }

              if(document.querySelector('.banner-image')){
                gsap.set('.banner-image', {
                  "clip-path": "inset(80% 0% 0% 0%)",
                  skewY: 3,
                })
                gsap.to('.banner-image', {
                  duration: .8,
                  "clip-path": "inset(0% 0% 0% 0%)",
                  ease: "sine.out",
                  skewY: 0,
                  delay: 1.2
                });

                gsap.set('.banner .shape', {
                  "clip-path": "inset(80% 0% 0% 0%)",
                  skewY: 3,
                })
                gsap.to('.banner .shape', {
                  duration: .8,
                  "clip-path": "inset(0% 0% 0% 0%)",
                  ease: "sine.out",
                  skewY: 0,
                  delay: 1.15
                });

                gsap.set('.banner .container', {
                  y: 100,
                  autoAlpha: 0,
                })
                gsap.to('.banner .container', {
                  duration: .8,
                  autoAlpha: 1,
                  y: 0,
                  ease: "sine.out",
                  delay: 1.2
                });
              }

              document.querySelectorAll("a, h2, h1, span, h3, h4").forEach((text) => {
                const tl = gsap.timeline({
                  scrollTrigger: {
                    trigger: text,
                    end: "+=60%",
                  },
                })
            
                tl.set(text, {
                  autoAlpha: 0,
                  y: this.randomY,
                }).fromTo(
                  text,
                  {
                    autoAlpha: 0,
                  },
                  {
                    autoAlpha: 1,
                    duration: 1.8,
                    y: 0,
                    duration: this.randomDuration,
                    ease: "power2.out",
                  }
                )
              })

              document.querySelectorAll("footer").forEach((text) => {
                  const tl = gsap.timeline({
                    scrollTrigger: {
                      trigger: text,
                      end: "+=60%",
                    },
                  });
              
                  tl.set(text, {
                    autoAlpha: 0,
                    y: this.randomY,
                  }).fromTo(
                    text,
                    {
                      autoAlpha: 0,
                    },
                    {
                      autoAlpha: 1,
                      duration: 1.8,
                      y: 0,
                      duration: this.randomDuration,
                      ease: "power2.out",
                    }
                  );
              });
            }
        })
        
        this.preload.onprogress = event => {
            // this.preloadProgress.textContent = event.progress + '%'
        }
    }
}